import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { LOGIN, OVERVIEW } from '@/constants/Routes'
import useSession from '@/utils/hooks/useSession'

interface PageProps {}

const Page: NextPage<PageProps> = () => {
  const session = useSession()
  const router = useRouter()

  useEffect(() => {
    (async () => {
      const nextRoute = session.isLoggedIn ? OVERVIEW : LOGIN

      await router.push(nextRoute)
    })()
  }, [session.isLoggedIn])

  return null
}

export default Page
